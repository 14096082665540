import React from 'react'

import { Col, Row } from 'antd'
import { navigate } from 'gatsby'

import { TGColumnProp } from '../Storybook/TableGrid'
import Amount from '../Amount'

import {
  StyledRightOutlined,
  StyledTextClick,
  StyledTextRed
} from './styledComponents'
import { QueryReportDayTicktesDetailSummary } from '../../types/Reports/ReporDayTickets'
import { getParameter } from '../../utils/queryString'
import { QueryBeesPayReportDayTicketsDetailSummary } from '../../types/Reports/BeesPay/ReportDayTickets'
import moment from 'moment'
import { setLocalStorageItem } from '../../utils/storage'
import { translateType } from '../../containers/BeesPayReportContainerByDayTickets/utils'

export const renderTotal = (
  _objt: string,
  row: QueryReportDayTicktesDetailSummary
): React.ReactElement => {
  const { amount, ticketId } = row

  const onClick = () => {
    if (amount === 0) {
      return
    }
    const storeId = getParameter('storeId')
    const addrUserId = getParameter('userId')
      ? `&userId=${getParameter('userId')}`
      : ''
    const addr = `/reports/sales/detail-ticket?ticketID=${ticketId}&storeId=${storeId}${addrUserId}`
    navigate(addr)
  }
  const totalAmount = parseFloat(String(amount)).toFixed(2)
  return (
    <StyledTextClick onClick={onClick}>
      <StyledTextRed styles={{ isred: amount === 0 }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            margin: '-10px 5px'
          }}
        >
          <Amount
            amount={totalAmount}
            amountSize={'18'}
            currecySize={'10'}
            centsSize={'10'}
          />
          <StyledRightOutlined
            data-testid="icon"
            styles={{ isred: amount === 0 }}
          />
        </div>
      </StyledTextRed>
    </StyledTextClick>
  )
}

export const renderOperations = (
  _objt: string,
  row: QueryReportDayTicktesDetailSummary
): React.ReactElement => {
  const { amount, ticketId, quantity } = row
  const getPluralProducto = quantity === 0 ? 'Producto' : 'Productos'
  return (
    <StyledTextRed styles={{ isred: amount === 0 }}>
      <Row>
        <Col>
          <div
            style={{
              fontWeight: 'bold'
            }}
          >
            {`#${ticketId.split('-')[0]}`}
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div
            style={{
              fontWeight: '200'
            }}
          >
            {`${quantity} ${getPluralProducto}`}
          </div>
        </Col>
      </Row>
    </StyledTextRed>
  )
}

export const ReportDayTicketsColumns: TGColumnProp[] = [
  { dataIndex: 'item', title: 'Column1', render: renderOperations },
  {
    dataIndex: 'total',
    title: 'Column3',
    render: renderTotal
  }
]

export const renderBeeOperations = (
  _objt: string,
  row: QueryBeesPayReportDayTicketsDetailSummary
): React.ReactElement => {
  const { amount, transactionServiceId: ticketId, type } = row
  return (
    <StyledTextRed styles={{ isred: amount === 0 }}>
      <Row>
        <Col>
          <div
            style={{
              fontWeight: 'bold'
            }}
          >
            {`Folio ${ticketId.split('-')[0]}`}
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div
            style={{
              fontWeight: '200'
            }}
          >
            {`${translateType(type)}`}
          </div>
        </Col>
      </Row>
    </StyledTextRed>
  )
}

export const renderBeesTotal = (
  _objt: string,
  row: QueryBeesPayReportDayTicketsDetailSummary
): React.ReactElement => {
  const { amount, transactionServiceId: ticketId, type } = row

  const onClick = () => {
    if (amount === 0) {
      return
    }
    const storeId = getParameter('storeId')
    const addrUserId = getParameter('userId')
      ? `&userId=${getParameter('userId')}`
      : ''
    const addr = `/reports/beespay/detail-ticket?ticketID=${ticketId}&storeId=${storeId}${addrUserId}`
    setLocalStorageItem('type', type)
    navigate(addr)
  }
  const totalAmount = parseFloat(String(amount)).toFixed(2)
  return (
    <StyledTextClick onClick={onClick}>
      <StyledTextRed styles={{ isred: amount === 0 }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            margin: '-10px 5px'
          }}
        >
          <Amount
            amount={totalAmount}
            amountSize={'18'}
            currecySize={'10'}
            centsSize={'10'}
          />
          <StyledRightOutlined
            data-testid="icon"
            styles={{ isred: amount === 0 }}
          />
        </div>
      </StyledTextRed>
    </StyledTextClick>
  )
}

export const ReportBeesDayTicketsColumns: TGColumnProp[] = [
  { dataIndex: 'item', title: 'Column1', render: renderBeeOperations },
  {
    dataIndex: 'total',
    title: 'Column3',
    render: renderBeesTotal
  }
]

export const ReportBeesByDayColumns: TGColumnProp[] = [
  { dataIndex: 'item', title: 'Column1', render: renderOperations },
  {
    dataIndex: 'total',
    title: 'Column3',
    render: renderTotal
  }
]

export const renderBeesDay = (
  _objt: string,
  row: BeesPayTypes.DetailSummary
): React.ReactElement => {
  const { totalAmount, item } = row
  return (
    <StyledTextRed styles={{ isred: totalAmount === 0 }}>
      <Row>
        <Col>
          <div
            style={{
              fontWeight: 'bold'
            }}
          >
            {moment(item).format('DD MMMM')}
          </div>
        </Col>
      </Row>
    </StyledTextRed>
  )
}

export const renderBeesByDayOperations = (
  _objt: string,
  row: BeesPayTypes.DetailSummary
): React.ReactElement => {
  const { totalAmount, totalOperations } = row
  return (
    <StyledTextRed styles={{ isred: totalAmount === 0 }}>
      <Row>
        <Col>
          <div
            style={{
              fontWeight: 'bold'
            }}
            data-test-id="data-test-id-operations"
          >
            {totalOperations} ops
          </div>
        </Col>
      </Row>
    </StyledTextRed>
  )
}

export const renderBeesByDayTotal = (
  _objt: string,
  row: BeesPayTypes.DetailSummary
): React.ReactElement => {
  const { totalAmount, item } = row

  const onClick = () => {
    if (totalAmount === 0) {
      return
    }
    const storeId = getParameter('storeId')
    const addr = `/reports/beespay/details-by-day-tickets?from=${item}&storeId=${storeId}`
    navigate(addr)
  }
  const amount = parseFloat(String(totalAmount)).toFixed(2)
  return (
    <StyledTextClick onClick={onClick}>
      <StyledTextRed styles={{ isred: totalAmount === 0 }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            margin: '-10px 5px'
          }}
        >
          <Amount
            amount={amount}
            amountSize={'18'}
            currecySize={'10'}
            centsSize={'10'}
          />
          <StyledRightOutlined
            data-testid="icon"
            styles={{ isred: totalAmount === 0 }}
          />
        </div>
      </StyledTextRed>
    </StyledTextClick>
  )
}

export const ReportBeesDaysColumns: TGColumnProp[] = [
  { dataIndex: 'item', title: 'Column1', render: renderBeesDay },
  {
    dataIndex: 'totalOperations',
    title: 'Column2',
    render: renderBeesByDayOperations
  },
  {
    dataIndex: 'totalAmount',
    title: 'Column3',
    render: renderBeesByDayTotal
  }
]
