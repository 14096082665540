import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { BeesPayReportByDays } from '../../components/BeesPayReportByDays'
import Loading from '../../components/Shared/Loading'
import { StoresSelect } from '../../components/StoresSelect'
import { getParameter } from '../../utils/queryString'
import { callProfileRedux } from '../ProfileContainer'
import { navigate } from 'gatsby'
import { transformQueryBeesReportByDaysToDataComponents } from './utils'
import { useQuery } from '@apollo/client'
import { GET_GENERAL_DETAIL } from '../../graphql/queries/Reports/beespay'
import moment from 'moment'
import { TileRowsProps } from '../../components/Storybook/TileRows'
import { TableGridProps } from '../../components/Storybook/TableGrid'

export const BeesPayReportByDaysContainer: React.FC = () => {
  const { user_data } = useSelector(callProfileRedux)
  const storeID: string | undefined = getParameter('storeId')
  const [loadingContainer, setLoadingContainer] = useState(true)
  const numberOfDay = parseInt(moment().startOf('month').format('D'))
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [rangesDates, setRangesDates] = useState({
    from: moment().startOf('month').format('DD/MM/yyyy'),
    to: moment()
      .subtract(numberOfDay == 1 ? 0 : 1, 'day')
      .format('DD/MM/yyyy')
  })
  const [tileRow, setTileRow] = useState<TileRowsProps>({ rowBody: [] })
  const [tableGrid, setTableGrid] = useState<TableGridProps>({
    data: [],
    columns: []
  })
  const { data: generalDetailData, loading: generalDetailLoading } = useQuery(
    GET_GENERAL_DETAIL,
    {
      variables: {
        from: rangesDates.from,
        to: rangesDates.to,
        storeID: storeID
      }
    }
  )

  useEffect(() => {
    if (generalDetailData && !generalDetailLoading) {
      const { tileRow: tileRowTransformed, tableGrid: tableGridTransformed } =
        transformQueryBeesReportByDaysToDataComponents({
          generalDetail: generalDetailData.getGeneralDetail[0]
        })
      setTileRow(tileRowTransformed)
      setTableGrid(tableGridTransformed)
      setLoadingContainer(false)
    }
  }, [generalDetailData, generalDetailLoading])

  const handleChangeSelect = () => {
    setLoadingContainer(true)
    // eslint-disable-next-line no-console
    console.log('the store has changed')
    setLoadingContainer(false)
  }

  const onClickBack = () => {
    navigate('/reports')
  }

  return (
    <React.Fragment>
      {!generalDetailLoading && !loadingContainer && (
        <React.Fragment>
          <StoresSelect
            onChange={handleChangeSelect}
            store={storeID}
            stores={
              user_data.getProfile
                ? user_data.getProfile?.stores
                : /* istanbul ignore next */ []
            }
          />
          <BeesPayReportByDays
            onClickBack={onClickBack}
            tileRow={tileRow}
            tableGrid={tableGrid}
          ></BeesPayReportByDays>
        </React.Fragment>
      )}
      {generalDetailLoading && <Loading />}
    </React.Fragment>
  )
}
