import React from 'react'
import { BeesPayReportByDaysContainer } from '../BeesPayReportByDaysContainer'

export const BeesPayReportContainer: React.FC = () => {
  return (
    <React.Fragment>
      <BeesPayReportByDaysContainer />
    </React.Fragment>
  )
}
