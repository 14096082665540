import moment from 'moment'
import { ReportBeesDaysColumns } from '../../components/ReportDayTicketsColumns'
import { BeesReportMonthsResume } from '../../components/ReportMonthsResume'
import { TableGridProps } from '../../components/Storybook/TableGrid'
import { TileRowsProps } from '../../components/Storybook/TileRows'
import { QueryBeesPayReportSummarySales } from '../../types/Reports/BeesPay/ReportDayTickets'
import { capitalizeFirstsLettersOfString } from '../../utils/general'

interface TransformQueryToDataTableGridProps {
  tileRow: TileRowsProps
  tableGrid: TableGridProps
}

const transformBeesByDaysToDataTableGrid = (
  detailSummary: BeesPayTypes.DetailSummary[]
): TableGridProps => {
  const dataTable: any = detailSummary
  const columns = ReportBeesDaysColumns
  const tableGrid = {
    data: dataTable,
    columns,
    isHeader: false,
    isVerticalLine: false
  }
  return tableGrid
}

export const transformBeesQueryTypeDayTickets = (
  ticketListSummary: BeesPayTypes.SummaryTransactions
): QueryBeesPayReportSummarySales => {
  const { fromTitleDetail } = ticketListSummary
  const date = fromTitleDetail.split('T')[0]
  const dateFormat = moment(date).format('MMMM YYYY')
  const dateFormatUpperLetter = capitalizeFirstsLettersOfString(dateFormat)
  const summarySales = {
    fromTitleDetail: dateFormatUpperLetter,
    toTitleDetail: '',
    totalCreditMobile: ticketListSummary.totalCreditMobile,
    totalCard: ticketListSummary.totalCard,
    totalServices: ticketListSummary.totalServices,
    totalCancelations: ticketListSummary.totalCancelations,
    totalBees: ticketListSummary.totalBees,
    totalOperations: ticketListSummary.totalNumberOperations
  }

  return summarySales
}

export const transformQueryBeesReportByDaysToDataComponents = (
  queryData: BeesPayTypes.Data
): TransformQueryToDataTableGridProps => {
  const { generalDetail } = queryData

  const { summaryTransactions, detailSummary } = generalDetail
  const tableGrid = transformBeesByDaysToDataTableGrid(detailSummary)

  const summarySales = transformBeesQueryTypeDayTickets(summaryTransactions)

  const tileRow = BeesReportMonthsResume(summarySales)

  return { tileRow, tableGrid }
}
