import moment from 'moment'
import { ReportBeesDayTicketsColumns } from '../../components/ReportDayTicketsColumns'

import { BeesReportMonthsResume } from '../../components/ReportMonthsResume'
import { TableGridProps } from '../../components/Storybook/TableGrid'
import { TileRowsProps } from '../../components/Storybook/TileRows'

import {
  QueryBeesPayReportGetTicketList,
  QueryBeesPayReportDayTicketsDetailSummary,
  QueryBeesPayReportSummarySales,
  BeesPayTicketListSummary
} from '../../types/Reports/BeesPay/ReportDayTickets'

import { capitalizeFirstsLettersOfString } from '../../utils/general'

interface TransformQueryToDataTableGridProps {
  tileRow: TileRowsProps
  tableGrid: TableGridProps
}

const transformBeesQueryToDataTableGrid = (
  detailSummary: QueryBeesPayReportDayTicketsDetailSummary[]
): TableGridProps => {
  const dataTable: any = detailSummary
  const columns = ReportBeesDayTicketsColumns
  const tableGrid = {
    data: dataTable,
    columns,
    isHeader: false,
    isVerticalLine: false
  }
  return tableGrid
}

export const transformBeesQueryTypeDayTickets = (
  ticketListSummary: BeesPayTicketListSummary | BeesPayTypes.SummaryTransactions
): QueryBeesPayReportSummarySales => {
  const { fromTitleDetail: dateFrom } = ticketListSummary
  const dateFormat = moment(dateFrom).format('dddd D MMMM ')
  const dateFormatUpperLetter = capitalizeFirstsLettersOfString(dateFormat)
  const summarySales = {
    fromTitleDetail: dateFormatUpperLetter,
    toTitleDetail: '',
    totalCreditMobile: ticketListSummary.totalCreditMobile,
    totalCard: ticketListSummary.totalCard,
    totalServices: ticketListSummary.totalServices,
    totalCancelations: ticketListSummary.totalCancelations,
    totalBees: ticketListSummary.totalBees,
    totalOperations: ticketListSummary.totalNumberOperations
  }

  return summarySales
}

export const transformQueryBeesReportToDataComponents = (
  queryData: QueryBeesPayReportGetTicketList
): TransformQueryToDataTableGridProps => {
  // eslint-disable-next-line no-console
  const { getTrxList } = queryData
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const detailData = getTrxList[0]
  const { detailSummary, summaryTransactions } = detailData
  const tableGrid = transformBeesQueryToDataTableGrid(detailSummary)

  const summarySales = transformBeesQueryTypeDayTickets(summaryTransactions)

  const tileRow = BeesReportMonthsResume(summarySales)
  return { tileRow, tableGrid }
}

export const translateType = (type: string): string => {
  switch (type) {
    case 'topup':
      return 'Recarga Telefónica'
    case 'billPayment':
      return 'Pago de servicio'
    case 'cashRegister':
      return 'Venta con tarjeta'
    case 'b2b':
      return 'Pago de orden Bees'
    case 'fundWallet':
      return 'Recarga de Saldo'
    case 'cancelation':
      return 'Cancelación exitosa'
    default:
      return ''
  }
}
