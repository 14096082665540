// Packages
import React from 'react'

// Components
import WithPrivateRoute from '../../../higherOrderComponents/WithPrivateRoute'
import SearchEngineOptimization from '../../../components/SEO'

import LayoutMain from '../../../layout'
import { BeesPayReportContainer } from '../../../containers/BeesPayReportContainer'

const BeesPay: React.FC = () => {
  return (
    <LayoutMain>
      <SearchEngineOptimization title="BeesPay" />
      <WithPrivateRoute component={BeesPayReportContainer} />
    </LayoutMain>
  )
}

export default BeesPay
