import styled from 'styled-components'
import { RightOutlined } from '@ant-design/icons'

interface StyledProps {
  styles: StyledTextRedProps
}

interface StyledTextRedProps {
  isred: boolean
}

export const StyledTextRed = styled.div<StyledProps>`
  color: ${(props) => (props.styles.isred ? 'red' : 'black')};
`

export const StyledRightOutlined = styled(RightOutlined)<StyledProps>`
  color: ${(props) =>
    props.styles.isred ? 'lightgray !important' : 'inherit'};
`

export const StyledTextClick = styled.span`
  cursor: pointer;

  :hover {
    font-weight: 700;
  }
`
