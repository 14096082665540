import { gql } from '@apollo/client'

export const GET_GENERAL_DETAIL = gql`
  query GetGeneralDetail($from: String!, $to: String!, $storeID: String!) {
    getGeneralDetail(from: $from, to: $to, storeID: $storeID) {
      summaryTransactions {
        fromTitleDetail
        storeId
        toTitleDetail
        totalCard
        totalCreditMobile
        totalServices
        totalBees
        totalCancelations
        totalNumberOperations
      }
      detailSummary {
        item
        totalOperations
        totalAmount
      }
    }
  }
`

export const GET_TRX_LIST = gql`
  query GetTrxList($from: String!, $storeID: String!) {
    getTrxList(from: $from, storeID: $storeID) {
      summaryTransactions {
        fromTitleDetail
        totalCard
        totalBees
        totalCancelations
        totalServices
        totalCreditMobile
        totalNumberOperations
      }
      detailSummary {
        transactionServiceId
        type
        amount
      }
    }
  }
`

export const GET_TICKET_DETAIL = gql`
  query GetTrxDetail($ticketID: String!) {
    getTrxDetail(ticketID: $ticketID) {
      storeID
      empresarioID
      transactionTypeID
      transactionServiceID
      transactionServiceName
      transactionServiceDescription
      amount
      amountCurrency
      transactionFee
      cardDigit
      cardBank
      cardBrand
      cardInstrument
      reference
      authorizationCode
      transactionCode
      status
      createdAt
      fundWallet
    }
  }
`

export const GET_TICKET_DETAIL_CANCELLED = gql`
  query GetTrxDetail($ticketID: String!) {
    getTrxDetail(ticketID: $ticketID) {
      cardDigit
      cardBank
      cardInstrument
    }
  }
`
